import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';
import { AuthSessionGuard } from './guard/auth-session.guard';
import { ExitPollGuard } from './guard/exit-poll.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'active-account',
    pathMatch: 'full'
  },
  {
    path: 'active-account',
    canActivate: [AuthSessionGuard],
    loadChildren: () => import('./modules/init-app/active-account/active-account.module').then( m => m.ActiveAccountPageModule)
  },
  {
    path: 'log-in',
    loadChildren: () => import('./modules/init-app/log-in/log-in.module').then( m => m.LogInPageModule)
  },
  {
    path: 'make-password',
    loadChildren: () => import('./modules/init-app/make-password/make-password.module').then( m => m.MakePasswordPageModule)
  },
  {
    path: 'recover-password',
    loadChildren: () => import('./modules/init-app/recover-password/recover-password.module').then( m => m.RecoverPasswordPageModule)
  },
  {
    path: 'confirmation-user',
    loadChildren: () => import('./modules/init-app/confirmation-user/confirmation-user.module').then( m => m.ConfirmationUserPageModule)
  },
  {
    path: 'restore-password',
    loadChildren: () => import('./modules/init-app/restore-password/restore-password.module').then( m => m.RestorePasswordPageModule)
  },
  {
    path: 'landing-introduction',
    loadChildren: () => import('./modules/init-app/landing-introduction/landing-introduction.module').then( m => m.LandingIntroductionPageModule)
  },
  {
    path: 'register-account-error',
    loadChildren: () => import('./modules/init-app/register-account-error/register-account-error.module').then( m => m.RegisterAccountErrorPageModule)
  },
  {
    path: 'send-pin',
    loadChildren: () => import('./modules/init-app/send-pin/send-pin.module').then( m => m.SendPinPageModule)
  },
  {
    path: 'confirm-pin',
    loadChildren: () => import('./modules/init-app/confirm-pin/confirm-pin.module').then( m => m.ConfirmPinPageModule)
  },
  {
    path: 'review-mail-pin',
    loadChildren: () => import('./modules/init-app/review-mail-pin/review-mail-pin.module').then( m => m.ReviewMailPinPageModule)
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard,ExitPollGuard],
    loadChildren: () => import('./modules/init-app/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'payroll-advance',
    canActivate: [ExitPollGuard],
    loadChildren: () => import('./modules/payroll-advance/payroll-advance.module').then( m => m.PayrollAdvancePageModule)
  },
  {
    path: 'refill',
    canActivate: [ExitPollGuard],
    loadChildren: () => import('./modules/refill/refill.module').then( m => m.RefillPageModule)
  },
  {
    path: 'services',
    canActivate: [ExitPollGuard],
    loadChildren: () => import('./modules/services/services.module').then( m => m.ServicesPageModule)
  },
  {
    path: 'message',
    canActivate: [ExitPollGuard],
    loadChildren: () => import('./modules/message/message.module').then( m => m.MessagePageModule)
  },
  {
    path: 'survey',
    loadChildren: () => import('./modules/survey/survey.module').then( m => m.SurveyPageModule)
  },
  {
    path: 'employee-voice',
    canActivate: [ExitPollGuard],
    loadChildren: () => import('./modules/employee-voice/employee-voice.module').then( m => m.EmployeeVoicePageModule)
  },
  {
    path: 'configuration',
    canActivate: [ExitPollGuard],
    loadChildren: () => import('./modules/configuration/configuration.module').then( m => m.ConfigurationPageModule)
  },
  {
    path: 'recognize-team',
    canActivate: [ExitPollGuard],
    loadChildren: () => import('./modules/recognize-team/recognize-team.module').then( m => m.RecognizeTeamPageModule)
  },
  {
    path: 'assist-secure',
    loadChildren: () => import('./modules/assist-secure/assist-secure.module').then( m => m.AssistSecurePageModule)
  },
  {
    path: 'discount',
    loadChildren: () => import('./modules/discount/discount.module').then( m => m.DiscountPageModule)
  },
  {
    path: 'corp-docs',
    loadChildren: () => import('./modules/corp-docs/corp-docs.module').then( m => m.CorpDocsPageModule)
  },
  {
    path: 'debts',
    loadChildren: () => import('./modules/debts/debts.module').then( m => m.DebtsPageModule)
  },
  {
    path: 'payslip',
    loadChildren: () => import('./modules/payslip/payslip.module').then(m => m.PayslipModule)
  },
  {
    path: 'requests',
    loadChildren: () => import('./modules/requests/requests.module').then( m => m.RequestsPageModule)
  },
  {
    path: 'update',
    loadChildren: () => import('./modules/init-app/update/update.module').then( m => m.UpdatePageModule)
  },
  {
    path: 'online-wellness',
    loadChildren: () => import('./modules/online-wellness/online-wellness.module').then( m => m.OnlineWellnessPageModule)
  },
  {
    path: 'moods',
    loadChildren: () => import('./modules/moods/moods.module').then( m => m.MoodsPageModule)
  },
  {
    path: 'corp-docs-company',
    loadChildren: () => import('./modules/corp-docs-company/corp-docs-company.module').then( m => m.CorpDocsCompanyPageModule)
  },
  {
    path: 'sua-letter',
    loadChildren: () => import('./modules/sua-letter/sua-letter.module').then(m => m.SuaLetterModule)
  },
  {
    path: 'review-new-device-pin',
    loadChildren: () => import('./modules/init-app/review-new-device-pin/review-new-device-pin.module').then( m => m.ReviewNewDevicePinPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

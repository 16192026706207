import { Component, Input, OnInit } from '@angular/core';
import { ModalController,NavController, ToastController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';
import { RecognizeService } from 'src/app/services/recognize.service';
import { AppStorageKey } from 'src/app/static';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-recognize-by',
  templateUrl: './recognize-by.page.html',
  styleUrls: ['./recognize-by.page.scss'],
})
export class RecognizeByPage implements OnInit {

  @Input() shipping;

  @Input() acknowledgment;

  hasCustomColors: boolean = false;

  user_exists:any=false
  constructor(
    private modalController:ModalController,
    public recognizeService:RecognizeService,
    private toastController:ToastController,
    private navCtrl: NavController,
    private storage: Storage,
    private authService:AuthService
    ) { }

  ngOnInit() {
    console.log("shipping", this.shipping);
    console.log("acknowledgment", this.acknowledgment);
    this.setCustomColors();
  }

  async setCustomColors(){
    await this.storage.get(AppStorageKey.HasCustomColors).then(
      data => {
        if(data) {
          this.hasCustomColors = data;
        } 
      },
      error => {
        console.log(error);
      }
    );
    this.verifyUser()
  }

  close(){
    this.modalController.dismiss();
  }

  verifyUser(){
    if(this.shipping && this.shipping.sender_email){
      this.authService.verifyUser(this.shipping.sender_email).subscribe(response => {
        if(response['status'] == 200)
          if(response['exists'])
          this.user_exists=true
          else
          this.user_exists=false
        
      },(err=>console.log('err',JSON.stringify(err))));
    }
  }
  
  markReaction(id, reaction){
    this.recognizeService.markReaction(id, reaction).subscribe(response => {
      if(response['status'] == 200){
        console.log('reaccion enviada',response['successMessage']);
        this.presentToast(response['successMessage'],'success');
        this.modalController.dismiss();
        this.navCtrl.navigateRoot(['recognize-team/my-recognizes']);
      }
    });
  }

  // Presenta el toast con el error
  async presentToast(msg,color) {
    const toast = await this.toastController.create({
        message: msg,
        duration: 5000,
        position: 'top',
        color: color,
        cssClass: 'toast',
    });
    toast.present();
  }
}

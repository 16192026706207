import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DatePipe } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';
import { AppStorageKey } from 'src/app/static';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-terms-format-modal',
  templateUrl: './terms-format-modal.component.html',
  styleUrls: ['./terms-format-modal.component.scss'],
  providers: [DatePipe]
})
export class TermsFormatModalComponent implements OnInit {

  @Input() signature_url: string;

  today: Date = new Date();

  user: any;

  hasCustomColors: boolean = false;

  constructor(public modalController : ModalController, private authService:AuthService, private storage: Storage) { }

  ngOnInit() {
    this.authService.user().subscribe(
      (data:any)=>{
        //console.log("data", data);
        this.user = data;
      }
    );
    this.setCustomColors();
  }

  async setCustomColors(){
    await this.storage.get(AppStorageKey.HasCustomColors).then(
      data => {
        if(data) {
          this.hasCustomColors = data;
        } 
      },
      error => {
        console.log(error);
      }
    );
  }

  closeModal(){
    this.modalController.dismiss();
  }
}

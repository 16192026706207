import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { EmployeeVoiceService } from 'src/app/services/employee-voice.service';
import { AppStorageKey } from 'src/app/static';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-view-employee-voice',
  templateUrl: './view-employee-voice.page.html',
  styleUrls: ['./view-employee-voice.page.scss'],
})
export class ViewEmployeeVoicePage implements OnInit {

  @Input() message:any;
  comment:any;
  hasCustomColors: boolean = false;

  constructor(private modalController:ModalController,  private storage: Storage, private employeeService:EmployeeVoiceService, private router:Router) { }


  async ngOnInit() {
    this.employeeService.getComment(this.message.id).subscribe((res: any) => {
      console.log("res",res);
      this.comment = res.data;
      if (res.data) {
        this.comment = res.data;
      } 
    });
    console.log(this.message);
    this.setCustomColors();
  }

  async setCustomColors(){
    await this.storage.get(AppStorageKey.HasCustomColors).then(
      data => {
        if(data) {
          this.hasCustomColors = data;
        } 
      },
      error => {
        console.log(error);
      }
    );
  }

  close(){
    this.modalController.dismiss();
  }

  reOpen(message){
    this.modalController.dismiss();
    this.router.navigate(["employee-voice/send-voice", { data: JSON.stringify(message)}]);
  }
}
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppStorageKey } from 'src/app/static';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-notice-privacy',
  templateUrl: './notice-privacy.component.html',
  styleUrls: ['./notice-privacy.component.scss'],
})
export class NoticePrivacyComponent implements OnInit {

  hasCustomColors: boolean = false;

  constructor(public modalController : ModalController, private storage: Storage) { }

  ngOnInit() {
    this.setCustomColors();
  }

  async setCustomColors(){
    await this.storage.get(AppStorageKey.HasCustomColors).then(
      data => {
        if(data) {
          this.hasCustomColors = data;
        } 
      },
      error => {
        console.log(error);
      }
    );
  }

  closeModal(){
    this.modalController.dismiss();
  }

}

export const environment = {
    production: true,
    API_URL: 'https://admin.paco.app/api',
    ONE_SIGNAL_APP_ID: '0dd2afc6-5242-40a1-89d7-b406c67686a8',
    FIREBASE_SENDER_ID: 'FIREBASE_SENDER_ID',
    MAPSKEY: 'pk.eyJ1IjoiamN2aXpjYWlubyIsImEiOiJja2E4Zml1NHowZTkzMnJwaWdiMHlsMjZtIn0.6ciM4BUEDiwrrJi6xqlnPg',
    SMARTLOOK_KEY: '4a0168938f55324aa5d5d67e7ad826f6066894f7',
    HELP_URL:'https://ayuda.paco.app/portal/es/kb/soporte-t%C3%A9cnico/aplicaci%C3%B3n-m%C3%B3vil',
    API_KEY_ZOHO_ANDROID:'NDX2fFtcVzvVHVBYDARpufH7otl7a8qkrX4zcn19gQLrPVjlDirBjF4IOelqCYfE',
    API_KEY_ZOHO_IOS:"NDX2fFtcVzvVHVBYDARpufH7otl7a8qkrX4zcn19gQLrPVjlDirBjF4IOelqCYfE",
    ACCESS_TOKEN_ZOHO_ANDROID:'harFESiTd133lccH46FbgQyCtRgo18ocLjRNZvptqx%2ByELLOUqhINFTlHnzkOERKSAd0fJLcN8q7B%2FvleUuBoQ%2BzQLL7Wt1HkqDXbFW%2F%2FL57PFKyDQj1Lw%3D%3D',
    ACCESS_TOKEN_ZOHO_IOS:'p3Ld3wSntiUSq85F9CMQ264K2ysAu96UXGEq5xAzN%2Fsz6mUTnNil5MGPk43VlTyNZOiReaH23ARJxWQs2zaFian07pCMCBKjczwDVoboZ78%3D',
    XML_BASE_URL:'https://admin.paco.app',
    BUGSNAG_API_KEY:'8bbb698c6e080ffebf89835df2d45f31',
    APP_ID_IOS:'1601873986',
    APP_GENERAL_NAME:'BCB'
};

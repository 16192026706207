import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { AppStorageKey } from 'src/app/static';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-terms-modal',
  templateUrl: './terms-modal.component.html',
  styleUrls: ['./terms-modal.component.scss'],
})
export class TermsModalComponent implements OnInit {

  @Input() signature_url: string;

  user: any;

  hasCustomColors: boolean = false;

  constructor(public modalController : ModalController, private router: Router, private authService:AuthService, private storage: Storage) { }

  ngOnInit() {
    this.authService.getToken().then(() => {
      if (this.authService.isLoggedIn) { 
        this.authService.user().subscribe((data:any)=>{
          console.log("data", data);
          this.user = data;
        });
      }
    });
    this.setCustomColors();
  }

  async setCustomColors(){
    await this.storage.get(AppStorageKey.HasCustomColors).then(
      data => {
        if(data) {
          this.hasCustomColors = data;
        } 
      },
      error => {
        console.log(error);
      }
    );
  }

  closeModal(){
    this.modalController.dismiss();
  }
}
